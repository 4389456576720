import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGClose = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-close'} {...props} >
      <g fill={'currentColor'} >
        <path d={'M23.49,24a.47.47,0,0,1-.35-.15L.15.86a.5.5,0,0,1,0-.71.5.5,0,0,1,.71,0l23,23a.5.5,0,0,1,0,.71A.51.51,0,0,1,23.49,24Z'} />
        <path d={'M.51,24a.51.51,0,0,1-.36-.15.5.5,0,0,1,0-.71l23-23a.5.5,0,0,1,.71,0,.5.5,0,0,1,0,.71l-23,23A.47.47,0,0,1,.51,24Z'} />
      </g>
    </IconWrapper>
  );
});
