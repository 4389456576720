import * as SVGFlags from './flags';

const LOCALES_FLAGS = Object.entries(SVGFlags)
  .reduce(
    (acc, [key, component]) => ({ ...acc, [key.replace('SVGFlag', '').toLowerCase()]: component }),
    {}
  );

const getLocale = code => code.split('-').map((x = '') => x.toLowerCase());

export const LocaleFlags = (code) => {
  let [base, short] = getLocale(code);

  if (!short) {
    const [userBase, userShort] = getLocale(navigator.language);
    if (base == userBase) short = userShort;
  }

  return LOCALES_FLAGS[`${base}_${short}`] || LOCALES_FLAGS[base];
};
