import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGClock = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-clock'} {...props} viewBox={'0 0 41 41'}>
      <g stroke={'currentColor'} fill={'none'}>
        <path
          d={'M20.1667 39.3333C30.7521 39.3333 39.3333 30.7521 39.3333 20.1667C39.3333 9.58121 30.7521 1 20.1667 1C9.58121 1 1 9.58121 1 20.1667C1 30.7521 9.58121 39.3333 20.1667 39.3333Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M30.168 30.1668L21.9297 21.9302'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M20.168 22.6667C21.5487 22.6667 22.668 21.5475 22.668 20.1667C22.668 18.786 21.5487 17.6667 20.168 17.6667C18.7873 17.6667 17.668 18.786 17.668 20.1667C17.668 21.5475 18.7873 22.6667 20.168 22.6667Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M20.168 11V17.6667'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </g>
    </IconWrapper>
  );
});
