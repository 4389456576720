import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/joy';
import { animated as a, useSpring } from 'react-spring';
import { CircleBar } from './countdown-timer';

import './CountdownTimer.scss';

export const CountdownTimer = (props) => {
  const {
    timeout,
    numberFormatter,
    labelFormatter,
    style,
    size,
    onTimeout,
    ...rest
  } = props;

  const [live, setLive] = useState(false);
  const [{ value }, set] = useSpring(
    () => ({
      from: {
        value: 0
      },
      config: { duration: 0 },
      onRest: () => setLive(false)
    })
  );

  const callback = useRef();
  callback.current = onTimeout;

  useEffect(
    () => {
      if (!timeout) return;
      setLive(true);
      set({
        from: { value: timeout },
        to: { value: 0 },
        config: {
          duration: timeout
        }
      });
      const callbackTimeout = setTimeout(
        () => callback.current(),
        timeout
      );
      return () => {
        clearTimeout(callbackTimeout);
        setLive(false);
      };
    },
    [timeout, set]
  );

  return (
    <div
      role={'timer'}
      aria-live={live}
      aria-atomic={true}
      className={'progress-countdown-timer'}
      {...rest}
      style={{
        ...style,
        width: size,
        height: size,
      }}
    >
      <div className={'progress-countdown-timer__labels'} >
        <Typography
          component={'h5'}
        >
          <a.span
            // eslint-disable-next-line react/no-children-prop
            children={value.to(time => numberFormatter(time))}
          />
        </Typography>
        <Typography level={'body-xs'}>
          <a.span
            // eslint-disable-next-line react/no-children-prop
            children={value.to(time => labelFormatter(time))}
          />
        </Typography>
      </div>
      <CircleBar
        size={size}
        value={value}
        timeout={timeout}
      />
    </div>
  );
};

CountdownTimer.propTypes = {
  timeout: PropTypes.number,
  numberFormatter: PropTypes.func,
  labelFormatter: PropTypes.func,
  style: PropTypes.object,
  size: PropTypes.number,
  onTimeout: PropTypes.func,
};

CountdownTimer.defaultProps = {
  size: 100,
  numberFormatter: v => (v / 1000).toFixed(0),
  labelFormatter: v => 'seconds',
  onTimeout: () => {},
};
