import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGHelp = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-help'} {...props} >
      <g fill={'currentColor'} >
        <path d={'M12,24A12,12,0,1,1,24,12,12,12,0,0,1,12,24ZM12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Z'} />
        <path d={'M12,17.83a.77.77,0,0,1-.78-.74.75.75,0,0,1,.21-.55.74.74,0,0,1,.54-.23h.07a.77.77,0,0,1,.7.73.79.79,0,0,1-.21.56A.8.8,0,0,1,12,17.83Z'} />
        <path d={'M12,15.5a.5.5,0,0,1-.5-.5v-.41a2.51,2.51,0,0,1,1.5-2.3A2.5,2.5,0,1,0,9.5,10a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5A3.5,3.5,0,0,1,12,6.5,3.5,3.5,0,0,1,15.5,10a3.5,3.5,0,0,1-2.1,3.21,1.5,1.5,0,0,0-.9,1.38V15A.5.5,0,0,1,12,15.5Z'} />
      </g>
    </IconWrapper>
  );
});
