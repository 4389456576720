import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';

export const useSentrySetup = (props) => {
  const {
    feedbackID,
    account_company_name,
    survey_name,
    survey_id,
    start_date,
    end_date,
    locale,
    preview,
    is_kiosk_mode
  } = props;

  useEffect(
    () => {
      Sentry.setUser({ id: feedbackID });

      Sentry.setContext(
        'survey',
        {
          Account: account_company_name,
          Survey: survey_name?.en,
          ID: survey_id,
          Start: start_date,
          End: end_date,
        }
      );

      Sentry.setContext(
        'respondent',
        {
          'Short UUID': feedbackID,
          Locale: locale,
          Preview: preview
        }
      );

      Sentry.setTag('kisok', is_kiosk_mode);
    },
    [
      account_company_name,
      end_date,
      feedbackID,
      is_kiosk_mode,
      locale,
      preview,
      start_date,
      survey_id,
      survey_name
    ]
  );
};
