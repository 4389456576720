import { useMemo, useRef }  from 'react';

export const useMemoObject = (object) => {
  const keys = useRef(Object.keys(object));
  const state = useMemo(
    () => object,
    keys.current.map(key => object[key])
  );
  return state;
};
