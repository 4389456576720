import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { SVGArrow } from '../main';
import { SVG_ROTATE_ARROW } from '../Utils';

export const SVGDropDownArrow = forwardRef((props, ref) => {
  const { isOpen, ...rest } = props;
  return (
    <SVGArrow
      ref={ref}
      rotate={isOpen ? SVG_ROTATE_ARROW.UP : SVG_ROTATE_ARROW.DOWN}
      {...rest}
    />
  );
});

SVGDropDownArrow.propTypes = {
  isOpen: PropTypes.bool
};

SVGDropDownArrow.defaultProps = {
  isOpen: false
};
