import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagES = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-es'} {...props} >
      <path d={'M0,12a11.77,11.77,0,0,0,.75,4.17l11.25,1,11.25-1a12,12,0,0,0,0-8.34L12,6.78.75,7.83A11.77,11.77,0,0,0,0,12Z'} fill={'#ffda44'} />
      <path d={'M23.25,7.83a12,12,0,0,0-22.5,0Z'} fill={'#d80027'} />
      <path d={'M.75,16.17a12,12,0,0,0,22.5,0Z'} fill={'#d80027'} />
    </IconWrapper>
  );
});
