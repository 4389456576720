import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useNavigateKeepParams = () =>  {
  const navigate = useNavigate();
  const { search } = useLocation();

  const navigateWithParams = useCallback(
    (path) => {
      navigate(path + search);
    },
    [navigate, search]
  );

  return navigateWithParams;
};
