import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagRO = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-ro'} {...props} >
      <circle cx={'11.95'} cy={'12'} r={'11.95'} fill={'#ffda45'} />
      <path d={'m24,12c0-5-3.11-9.46-7.79-11.2v22.41c4.68-1.74,7.79-6.21,7.79-11.2Z'} fill={'#d80027'} />
      <path d={'m.11,12c0,5,3.11,9.46,7.79,11.2V.8C3.22,2.54.11,7.01.11,12Z'} fill={'#0053b5'} />
    </IconWrapper>
  );
});
