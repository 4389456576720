import { createRoot } from 'react-dom/client';
import { Suspense } from 'react';

import './lib/SentryInit';
import './polyfills';
import './i18n';

import { ErrorBoundary } from 'elements/boundary';
import { AppSkeleton } from './AppSkeleton';

import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Suspense fallback={<AppSkeleton />}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Suspense>
);
