import { Alert, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { SVGAlertSolid } from 'icons';

const WARNING_COLOUR = 'warning';

export const Warning = ({ title: propsTitle, message }) => {
  const { t } = useTranslation();

  const title = t(propsTitle);

  return (
    <Alert
      sx={{
        mt: 2,
        alignItems: 'flex-start'
      }}
      startDecorator={(
        <SVGAlertSolid
          size={16}
          color={WARNING_COLOUR}
          ariaHidden={'true'}
          style={{ marginTop: '0.125rem' }}
        />
      )}
      severity={WARNING_COLOUR}
      color={WARNING_COLOUR}
      key={title}
    >
      <div>
        <Typography
          level={'body-sm'}
          color={WARNING_COLOUR}
          sx={{ fontWeight: 500 }}
        >
          {title}
        </Typography>
        <Typography
          level={'body-sm'}
          color={WARNING_COLOUR}
        >
          {t(message)}
        </Typography>
      </div>
    </Alert>
  );
};
