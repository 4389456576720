import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagZH_CN = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-zh-CN'} {...props} >
      <circle cx={'12'} cy={'12'} r={'12'} fill={'#d80027'} />
      <path d={'M6.62,7.3l1,3.19H11l-2.71,2,1,3.19-2.71-2-2.71,2,1-3.18-2.71-2H5.59Z'} fill={'#ffda44'} />
      <path d={'M14.28,18.59l-.79-1-1.17.46L13,17l-.79-1,1.21.33.68-1.06.07,1.26,1.22.32-1.18.45Z'} fill={'#ffda44'} />
      <path d={'M15.86,15.73l.37-1.2-1-.73,1.25,0,.37-1.2.41,1.19H18.5l-1,.75.4,1.19-1-.73Z'} fill={'#ffda44'} />
      <path d={'M18,8.81l-.55,1.13.9.87-1.24-.17-.56,1.12-.21-1.24-1.25-.17,1.12-.59L16,8.52l.9.87Z'} fill={'#ffda44'} />
      <path d={'M14.32,5.39l-.1,1.25,1.17.47-1.23.3-.08,1.26L13.41,7.6l-1.22.3.81-1-.66-1.07,1.16.48Z'} fill={'#ffda44'} />
    </IconWrapper>
  );
});
