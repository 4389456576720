import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagBI = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-bi'} {...props} >
      <path d={'M24,12a11.82,11.82,0,0,0-.1-1.57H10.43L3.51,3.51a12,12,0,0,0,0,17h0l6.92-6.92H23.9A11.57,11.57,0,0,0,24,12Z'} />
      <path d={'M24,12.27v0Z'} fill={'#f0f0f0'} />
      <path d={'M24,12.6v0Z'} fill={'#f0f0f0'} />
      <path d={'M24,12.86a.31.31,0,0,1,0,.1S24,12.9,24,12.86Z'} fill={'#f0f0f0'} />
      <path d={'M23.93,10.69v.12S23.93,10.73,23.93,10.69Z'} fill={'#f0f0f0'} />
      <path d={'M24,11.33a.17.17,0,0,0,0,.07A.17.17,0,0,1,24,11.33Z'} fill={'#f0f0f0'} />
      <path d={'M24,11.66v.07A.08.08,0,0,0,24,11.66Z'} fill={'#f0f0f0'} />
      <path d={'M24,11s0,.06,0,.1S24,11.07,24,11Z'} fill={'#f0f0f0'} />
      <path d={'M23.9,10.43c0,.05,0,.09,0,.13a12,12,0,0,0-20.4-7l6.92,6.91Z'} fill={'#d80027'} />
      <path d={'M23.9,13.56H10.43L3.51,20.48a12,12,0,0,0,20.4-7S23.9,13.52,23.9,13.56Z'} fill={'#6da544'} />
      <path d={'M23.94,13.19s0,.08,0,.12S23.94,13.23,23.94,13.19Z'} fill={'#f0f0f0'} />
      <path d={'M24,11.14c0,.06,0,.12,0,.19S24,11.2,24,11.14Z'} />
      <path d={'M23.91,10.56s0,.09,0,.13S23.92,10.6,23.91,10.56Z'} />
      <path d={'M23.94,10.81A1.79,1.79,0,0,1,24,11,1.79,1.79,0,0,0,23.94,10.81Z'} />
      <path d={'M24,12v0Z'} />
      <path d={'M24,12.67c0,.06,0,.13,0,.19S24,12.73,24,12.67Z'} />
      <path d={'M24,13c0,.08,0,.15,0,.23A1.79,1.79,0,0,0,24,13Z'} />
      <path d={'M23.93,13.31s0,.09,0,.13S23.92,13.35,23.93,13.31Z'} />
      <path d={'M24,11.73v0Z'} />
      <path d={'M24,11.4a2.17,2.17,0,0,1,0,.26A2.17,2.17,0,0,0,24,11.4Z'} />
      <path d={'M24,12.34a2.26,2.26,0,0,1,0,.26A2.26,2.26,0,0,0,24,12.34Z'} />
      <path d={'M24,11.47H10L2.81,4.29a4.71,4.71,0,0,0-.64.83L9,12,2.16,18.87a5.82,5.82,0,0,0,.64.84L10,12.52H24c0-.18,0-.35,0-.52S24,11.59,24,11.47Z'} fill={'#ffda44'} />
      <path d={'M2.92,14.56v-1a1.82,1.82,0,0,0,1.82-1.82,1.29,1.29,0,0,0-1.29-1.28.87.87,0,0,0-.88.87.56.56,0,0,0,.56.56.31.31,0,0,0,.32-.31h1a1.32,1.32,0,0,1-1.32,1.32,1.56,1.56,0,0,1-1.56-1.57A1.88,1.88,0,0,1,3.45,9.44a2.3,2.3,0,0,1,2.29,2.29A2.83,2.83,0,0,1,2.92,14.56Z'} fill={'#ffda44'} />
    </IconWrapper>
  );
});
