import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGArrow = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-arrow'} {...props}>
      <g fill={'currentColor'}>
        <path d={'M12,16.33A2,2,0,0,1,10.91,16L.21,8.58a.5.5,0,0,1-.12-.69.5.5,0,0,1,.7-.13l10.67,7.43a1.06,1.06,0,0,0,1.1,0L23.21,7.76a.5.5,0,0,1,.58.82L13.11,16A2.16,2.16,0,0,1,12,16.33Z'} />
      </g>
    </IconWrapper>
  );
});
