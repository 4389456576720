import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGInfo = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-info'} {...props} >
      <g fill={'none'} stroke={'currentColor'} >
        <circle
          cx={'12'} cy={'12'} r={'11'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path d={'M14.5,17H13a1,1,0,0,1-1-1V9.5a.5.5,0,0,0-.5-.5H10'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <line
          x1={'11.74'} y1={'6.5'} x2={'11.74'} y2={'6.5'}
          strokeLinecap={'round'} strokeLinejoin={'round'}
        />
        <path d={'M11.74,6.5a.25.25,0,0,0,0,.5.25.25,0,1,0,0-.5'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      </g>
    </IconWrapper>
  );
});
