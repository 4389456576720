import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagSM = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-sm'} {...props} >
      <path d={'M24,12A12,12,0,0,1,0,12L12,0A12,12,0,0,1,24,12Z'} fill={'#d80027'} />
      <path d={'M12,12V0A12,12,0,0,0,0,12Z'} fill={'#0052b4'} />
      <path d={'M9.61,7.83l.2.59h.63l-.51.37.19.6L9.61,9l-.51.37.2-.6-.51-.37h.63Z'} fill={'#f0f0f0'} />
      <path d={'M6.45,3.13l.33,1h1L7,4.74l.32,1-.85-.62-.84.62.32-1-.85-.61h1Z'} fill={'#f0f0f0'} />
      <path d={'M9.59,4.17l.32,1H11l-.85.62.32,1-.85-.61-.84.61.32-1-.85-.62h1Z'} fill={'#f0f0f0'} />
      <path d={'M7.11,8.35l.32,1H8.48L7.63,10,8,11l-.85-.62L6.26,11l.33-1-.85-.62h1Z'} fill={'#f0f0f0'} />
      <path d={'M4.37,5.74l.32,1h1l-.85.61.33,1-.85-.62-.85.62.32-1L3,6.74H4Z'} fill={'#f0f0f0'} />
    </IconWrapper>
  );
});
