import { forwardRef } from 'react';
import { DEFAULT_AYT_ORANGE } from 'lib/Enum';
import { IconWrapper } from '../IconWrapper';

export const SVGLogo = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-logo'} {...props} ariaLabel={'askyourteam-logo'}>
      <title id={'askyourteam-logo'}>Askyourteam logo</title>
      <g fill={DEFAULT_AYT_ORANGE}>
        <path d={'M8.15,8.44H9.39l.24.63h.87L9.16,5.85H8.41L7.06,9.07h.85Zm.63-1.72.4,1.09H8.37Z'} />
        <path d={'M11.8,8.52a.88.88,0,0,1-.69-.34l-.51.52a1.67,1.67,0,0,0,1.2.47A1.1,1.1,0,0,0,13,8.09c0-.64-.56-.83-1-1s-.49-.16-.49-.38.25-.33.45-.33a.72.72,0,0,1,.57.29l.49-.53A1.65,1.65,0,0,0,12,5.78c-.61,0-1.23.31-1.23,1s.4.79.83.91.67.22.67.47S12,8.52,11.8,8.52Z'} />
        <path d={'M14.31,7.59h0l1.2,1.48h1L15.06,7.34l1.38-1.47h-1L14.33,7.16h0V5.87h-.77v3.2h.77Z'} />
        <path d={'M6.37,13.3h.77V11.94L8.37,10.1H7.46l-.69,1.19L6.1,10.1H5.15l1.22,1.84Z'} />
        <path d={'M15.59,13.3h.77V12h.26l.69,1.28h.91l-.83-1.38a.89.89,0,0,0,.67-.87c0-.73-.61-1-1.24-1H15.59Zm.77-2.57h.4c.22,0,.53.06.53.36s-.31.39-.57.39h-.36Z'} />
        <path d={'M13,10.1H12.2v2a1.38,1.38,0,0,0,2.76,0v-2h-.77V12a.61.61,0,1,1-1.22.12V10.1Z'} />
        <path d={'M8.23,11.68a1.66,1.66,0,0,0,1.62,1.7H10A1.69,1.69,0,0,0,10.09,10H10a1.65,1.65,0,0,0-1.74,1.56Zm2.68,0a.93.93,0,0,1-.84,1,.92.92,0,0,1-1-.84v-.15a.9.9,0,0,1,.83-1H10a.9.9,0,0,1,.92.9Z'} />
        <path d={'M5.21,15h.92v2.54h.79V15h.89v-.67H5.21Z'} />
        <path d={'M8.92,16.22h1.34v-.63H8.92V15h1.4v-.65H8.17v3.21H10.4v-.65H8.92Z'} />
        <path d={'M16.17,16.4h0l-.73-2.07H14.33v3.21h.73l0-2.28h0l.81,2.28h.55l.85-2.28h0l0,2.28H18V14.33H16.86Z'} />
        <path d={'M12,14.33,10.6,17.56h.85l.25-.63h1.23l.25.63h.87l-1.34-3.23Zm0,2,.4-1.09.41,1.09Z'} />
        <path d={'M12,.09a11.91,11.91,0,0,0-8.13,20.6l.49-3.05a9.54,9.54,0,1,1,2.28,2.17l-2.75.9A11.91,11.91,0,1,0,12,.09Z'} />
      </g>
    </IconWrapper>
  );
});
