import { useMemo } from 'react';
import { CssVarsProvider, Stack, Typography, Link } from '@mui/joy';
import { useSurveyTheme } from 'hooks';
import { PRIMARY_COLOR } from 'lib/Enum';
import { PageFooter } from 'elements/PageFooter';

export const ErrorRenderer = (props) => {
  const {
    status
  } = props;

  const title = useMemo(
    () => {
      if (status == 404) return 'Sorry, we can’t find that page';
      return 'Something has gone wrong';
    },
    [status]
  );

  const body = useMemo(
    () => {
      if (status == 404) return 'Please check the survey URL (website address). If you’ve copy and pasted the URL from an email or text message, make sure all of the URL was selected.';
      return 'Sorry about that! We\'ve got our team working to fix it. Please wait a few minutes, then try reloading the page.';
    },
    [status]
  );

  const { joyTheme } = useSurveyTheme({ colour: PRIMARY_COLOR._500 });

  return (
    <CssVarsProvider theme={joyTheme}>
      <div className={'app'}>
        <div className={'app__centered'}>
          <Stack
            spacing={5}
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            textAlign={'center'}
            sx={{ maxWidth: '582px', flex: 1, margin: 'auto' }}
            data-component={'error-boundary-renderer'}
          >
            <Typography
              level={'h1'}
              sx={{
                fontWeight: 300,
                fontSize: { xs: 48, md: 64 },
              }}
            >
              {title}
            </Typography>
            {status && (
              <Typography level={'body-md'}>
                Error code: {status}
              </Typography>
            )}
            <Typography level={'body-md'}>
              {body}
            </Typography>

            <Typography level={'body-md'}>
              <strong>Still getting an error?</strong> Email us at <Link underline={'always'} href={'mailto:support@askyourteam.com'}>support@askyourteam.com</Link>
            </Typography>
          </Stack>
        </div>
        <PageFooter
          is_kiosk_mode={false}
        />
      </div>
    </CssVarsProvider>
  );
};

export default ErrorRenderer;
