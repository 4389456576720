import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGNonConfidential = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-non-confidential'} {...props}viewBox={'0 0 32 32'}>
      <g stroke={'currentColor'} fill={'none'}>
        <path d={'M20.0013 12.667H12.0013C11.2649 12.667 10.668 13.2639 10.668 14.0003V20.667C10.668 21.4034 11.2649 22.0003 12.0013 22.0003H20.0013C20.7377 22.0003 21.3346 21.4034 21.3346 20.667V14.0003C21.3346 13.2639 20.7377 12.667 20.0013 12.667Z'}  strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M16.0013 18.6667C16.7377 18.6667 17.3346 18.0697 17.3346 17.3333C17.3346 16.597 16.7377 16 16.0013 16C15.2649 16 14.668 16.597 14.668 17.3333C14.668 18.0697 15.2649 18.6667 16.0013 18.6667Z'}  strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M18.6654 12.6663V9.99967C18.6654 9.29243 18.3844 8.61415 17.8843 8.11406C17.3842 7.61396 16.7059 7.33301 15.9987 7.33301C15.2915 7.33301 14.6132 7.61396 14.1131 8.11406C13.613 8.61415 13.332 9.29243 13.332 9.99967'}  strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M2 5.03317V15.2158C2.03918 18.5805 3.11144 21.8518 5.07158 24.5868C7.03172 27.3218 9.78503 29.3883 12.9587 30.5065L14.4533 31.0585C15.4518 31.4252 16.5482 31.4252 17.5467 31.0585L19.0413 30.5065C22.215 29.3883 24.9683 27.3218 26.9284 24.5868C28.8886 21.8518 29.9608 18.5805 30 15.2158V5.03317C29.9987 4.63311 29.8799 4.24224 29.6586 3.909C29.4372 3.57576 29.1229 3.31482 28.7547 3.1585C24.7157 1.4727 20.3764 0.624897 16 0.666499C11.6236 0.624897 7.28428 1.4727 3.24533 3.1585C2.87707 3.31482 2.56278 3.57576 2.34142 3.909C2.12005 4.24224 2.00134 4.63311 2 5.03317Z'}  strokeLinecap={'round'} strokeLinejoin={'round'} />
      </g>
    </IconWrapper>
  );
});
