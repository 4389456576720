import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagHI = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-hi'} {...props} >
      <path d={'M12.21,0C12.07,.07,11.93,.08,11.79,0h.42Z'} fill={'#fda128'} />
      <path d={'M12.21,0C12.07,.08,11.93,.07,11.79,0h.42Z'} fill={'#fda128'} />
      <path d={'M11.79,24.02c.14-.08,.28-.07,.42,0h-.42Z'} fill={'#7bad57'} />
      <path d={'M11.81,24.02c.12-.07,.25-.07,.37,0h-.37Z'} fill={'#7bad57'} />
      <path d={'M12.07,0C12.03,.07,11.98,.06,11.93,0h.14Z'} fill={'#fac681'} />
      <path d={'M12.05,0C12.02,.05,11.99,.06,11.95,0h.09Z'} fill={'#fac886'} />
      <circle cx={'12'} cy={'12'} r={'12'} fill={'#fff'} />
      <path d={'M22.9,7C21,2.87,16.84,0,12,0S3,2.87,1.1,7H22.9Z'} fill={'#fe9711'} />
      <path d={'M1.1,17c1.9,4.13,6.06,7,10.9,7s9-2.87,10.9-7H1.1Z'} fill={'#6da444'} />
      <path d={'M12.01,7.83c-2.29-.01-4.17,1.85-4.18,4.16-.01,2.28,1.84,4.17,4.11,4.19,2.31,.02,4.21-1.83,4.23-4.12,.01-2.34-1.83-4.22-4.16-4.23Zm-2.39,3.12c.25,.34,.48,.65,.71,.96,.05,.07,.04,.12,0,.19-.23,.31-.45,.61-.7,.96-.29-.72-.3-1.4,0-2.11Zm.27,2.59c.44-.05,.83-.09,1.22-.13,.08,0,.11,.05,.13,.1,.15,.35,.31,.7,.47,1.08-.76-.1-1.34-.44-1.83-1.05Zm1.35-3.05c-.03,.06-.05,.11-.13,.1-.39-.04-.78-.09-1.22-.13,.48-.61,1.06-.95,1.83-1.06-.17,.39-.32,.74-.47,1.09Zm2.77-.14c.07,.08,.05,.12-.05,.13-.35,.04-.7,.07-1.05,.11-.1,.01-.14-.04-.17-.12-.15-.34-.3-.69-.47-1.07,.73,.1,1.29,.41,1.74,.94Zm-1.73,4.25c.17-.39,.33-.75,.48-1.1,.02-.05,.05-.1,.12-.09,.39,.04,.79,.08,1.22,.13-.48,.62-1.07,.95-1.83,1.06Zm2.1-1.54c-.25-.34-.48-.65-.71-.96-.05-.07-.04-.12,0-.19,.23-.31,.45-.61,.7-.96,.29,.72,.29,1.4,0,2.11Z'} fill={'#0052b3'} />
    </IconWrapper>
  );
});
