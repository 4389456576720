import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagEN_NZ = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-en-NZ'} {...props} >
      <path d={'M12,0a12,12,0,0,0,0,24h0A12,12,0,0,0,12,0Z'} fill={'#273375'} />
      <path d={'M2.52,4.65Q2.24,5,2,5.4H3.27Z'} fill={'#29337a'} />
      <polygon points={'8.72 12 9.87 12 8.72 10.84 8.72 12'} fill={'#29337a'} />
      <path d={'M4.89,2.34a10.19,10.19,0,0,0-.8.64l.8.8Z'} fill={'#29337a'} />
      <polygon points={'3.73 12 4.88 12 4.88 10.84 3.73 12'} fill={'#29337a'} />
      <path d={'M12,0A11.81,11.81,0,0,0,8.72.46V3.78L12,.5V0Z'} fill={'#29337a'} />
      <polygon points={'12 10.89 12 9.23 10.33 9.23 12 10.89'} fill={'#29337a'} />
      <path d={'M.5,12,3.27,9.23H.33A11.54,11.54,0,0,0,0,12Z'} fill={'#29337a'} />
      <polygon points={'10.33 5.39 12 5.39 12 3.73 10.33 5.39'} fill={'#29337a'} />
      <polygon points={'12 11.24 12 10.89 10.33 9.23 12 9.23 12 8.63 9.39 8.63 12 11.24'} fill={'#fff'} />
      <path d={'M3.27,5.4H2c-.13.19-.25.39-.37.59H4.14l-1.5-1.5-.12.16Z'} fill={'#fff'} />
      <polygon points={'4.88 10.84 4.88 12 5.48 12 5.48 9.97 3.45 12 3.73 12 4.88 10.84'} fill={'#fff'} />
      <path d={'M8.72.46l-.6.18v4L12,.77V.5L8.72,3.78Z'} fill={'#fff'} />
      <path d={'M4.86,9.23l0,0h0l.57-.58h-5c0,.2-.11.4-.15.6H3.27L.5,12H2.09L4.86,9.23Z'} fill={'#fff'} />
      <path d={'M.5,12,3.27,9.23Z'} fill={'#fff'} />
      <polygon points={'12 5.99 12 5.39 10.33 5.39 12 3.73 12 2.13 8.14 5.99 12 5.99'} fill={'#fff'} />
      <path d={'M12,3.73,10.33,5.4Z'} fill={'#fff'} />
      <path d={'M4.89,3.78,4.09,3a10.07,10.07,0,0,0-.81.79l1.61,1.6h0v0L5.48,6v-4c-.2.13-.4.26-.59.41V3.78Z'} fill={'#fff'} />
      <polygon points={'8.72 10.84 9.87 12 11.4 12 8.12 8.72 8.12 12 8.72 12 8.72 10.84'} fill={'#fff'} />
      <path d={'M4.89,5.37v0Z'} fill={'#d32030'} />
      <path d={'M10,9.23l-.59-.6H12V6H8.14l0,0V6h0L12,2.13V.77L8.12,4.65v-4A12.43,12.43,0,0,0,5.48,1.93V6l0,0h0V6L4.9,5.39h0v0L3.28,3.77a8.67,8.67,0,0,0-.64.72L4.14,6H1.61A12.88,12.88,0,0,0,.48,8.63h5l0,0v0h0l-.57.58v0h0L2.09,12H3.45l2-2v2H8.12V8.72L11.4,12h.45l.15-.16v-.6Z'} fill={'#d32030'} />
      <polygon points={'8.12 4.65 12 0.77 12 0.77 8.12 4.65'} fill={'#d32030'} />
      <polygon points={'4.88 9.23 4.88 9.21 4.86 9.23 4.88 9.23'} fill={'#d32030'} />
      <polygon points={'5.48 8.61 5.46 8.63 5.48 8.63 5.48 8.61'} fill={'#d32030'} />
      <polygon points={'8.12 6.01 8.14 5.99 8.12 5.99 8.12 6.01'} fill={'#d32030'} />
      <polygon points={'4.88 5.39 4.91 5.39 4.9 5.39 4.88 5.38 4.88 5.39'} fill={'#d32030'} />
      <polygon points={'5.5 5.99 5.48 5.97 5.48 5.99 5.5 5.99'} fill={'#d32030'} />
      <polygon
        points={'21.31 9.23 21.57 10.03 22.41 10.03 21.73 10.52 21.99 11.32 21.31 10.83 20.63 11.32 20.89 10.52 20.21 10.03 21.05 10.03 21.31 9.23'} fill={'#d80027'} stroke={'#fff'} strokeMiterlimit={'10'}
        strokeWidth={'0.25'}
      />
      <polygon
        points={'18.31 14.97 18.7 16.17 19.96 16.17 18.94 16.91 19.33 18.1 18.31 17.36 17.3 18.1 17.68 16.91 16.67 16.17 17.93 16.17 18.31 14.97'} fill={'#d80027'} stroke={'#fff'} strokeMiterlimit={'10'}
        strokeWidth={'0.25'}
      />
      <polygon
        points={'18.43 5.58 18.75 6.58 19.8 6.58 18.95 7.19 19.27 8.19 18.43 7.58 17.58 8.19 17.9 7.19 17.06 6.58 18.1 6.58 18.43 5.58'} fill={'#d80027'} stroke={'#fff'} strokeMiterlimit={'10'}
        strokeWidth={'0.25'}
      />
      <polygon
        points={'15.54 9.2 15.93 10.39 17.19 10.39 16.17 11.13 16.56 12.33 15.54 11.59 14.53 12.33 14.92 11.13 13.9 10.39 15.16 10.39 15.54 9.2'} fill={'#d80027'} stroke={'#fff'} strokeMiterlimit={'10'}
        strokeWidth={'0.25'}
      />
    </IconWrapper>
  );
});
