import { extendTheme } from '@mui/joy/styles';
import { calcColourWithAlpha, changeCSSVars, colourContrastRatio } from 'lib/Utils';
import {
  FONT_FAMILY,
  SYSTEM_COLOR,
  DEFAULT_AYT_ORANGE,
  PRIMARY_COLOR,
  SUCCESS_COLOR,
  DANGER_COLOUR,
  CONTRAST_GRAY
} from 'lib/Enum';

const MINIMUM_CONTRAST_RATIO = 4.5; // WCAG 2.1

export const useSurveyTheme = ({ colour }) => {
  if (colour == undefined) return {};

  const isAYTOrange = colour.toUpperCase() == DEFAULT_AYT_ORANGE;
  const badContrast = colourContrastRatio(colour, '#ffffff') <= MINIMUM_CONTRAST_RATIO || isAYTOrange;

  if (!isAYTOrange) {
    SYSTEM_COLOR.PRIMARY = colour;
    SYSTEM_COLOR.PRIMARY_COLOR_FADED = calcColourWithAlpha(colour, 0.8);
    SYSTEM_COLOR.PRIMARY_COLOR_LIGHT_FADED = calcColourWithAlpha(colour, 0.2);

    changeCSSVars({
      '--theme-color': colour,
      '--theme-color-75': calcColourWithAlpha(colour, 0.75),
      '--theme-color-50': calcColourWithAlpha(colour, 0.5),
      '--theme-color-25': calcColourWithAlpha(colour, 0.25),
    });

    PRIMARY_COLOR._50 = calcColourWithAlpha(colour, 0.1);
    PRIMARY_COLOR._100 = calcColourWithAlpha(colour, 0.2);
    PRIMARY_COLOR._200 = calcColourWithAlpha(colour, 0.5);
    PRIMARY_COLOR._300 = calcColourWithAlpha(colour, 0.7);
    PRIMARY_COLOR._400 = calcColourWithAlpha(colour, 0.9);
    PRIMARY_COLOR._500 = colour;
    PRIMARY_COLOR._600 = calcColourWithAlpha(colour, 0.9, '#000000');
    PRIMARY_COLOR._700 = calcColourWithAlpha(colour, 0.7, '#000000');
    PRIMARY_COLOR._800 = calcColourWithAlpha(colour, 0.5, '#000000');
    PRIMARY_COLOR._900 = calcColourWithAlpha(colour, 0.4, '#000000');
    PRIMARY_COLOR.softBg = PRIMARY_COLOR._100;
  }

  const joyTheme = extendTheme({
    'breakpoints': {
      values: {
        xs: 0,
        sm: 380, // default is 600
        md: 767, // default is 900
        lg: 1200,
        xl: 1536,
      },
    },
    'fontFamily': {
      'body': FONT_FAMILY,
      'display': FONT_FAMILY,
    },
    'fontWeight': {
      'sm': 300,
      'md': 400,
      'lg': 400,
      'xl': 500
    },
    'colorSchemes': {
      'light': {
        'palette': {
          'primary': {
            50: PRIMARY_COLOR._50,
            100: PRIMARY_COLOR._100,
            200: PRIMARY_COLOR._200,
            300: PRIMARY_COLOR._300,
            400: PRIMARY_COLOR._400,
            500: PRIMARY_COLOR._500,
            600: PRIMARY_COLOR._600,
            700: PRIMARY_COLOR._700,
            800: PRIMARY_COLOR._700,
            900: PRIMARY_COLOR._700,

            softBg: PRIMARY_COLOR.softBg,
          },
          'neutral': {
            50: SYSTEM_COLOR.GRAY._100,
            100: SYSTEM_COLOR.GRAY._200,
            200: SYSTEM_COLOR.GRAY._300,
            300: SYSTEM_COLOR.GRAY._400,
            // neutral.400 gets used for disabled states so it needs to meet contrast ratios
            400: SYSTEM_COLOR.GRAY._700,
            500: SYSTEM_COLOR.GRAY._700,
            600: SYSTEM_COLOR.GRAY._700,
            700: SYSTEM_COLOR.TEXT_BLACK,
            800: SYSTEM_COLOR.GRAY._900,
            900: SYSTEM_COLOR.GRAY._1000,
          },
          'success': {
            500: SUCCESS_COLOR._500,
            600: SUCCESS_COLOR._600,
            700: SUCCESS_COLOR._700,
            800: SUCCESS_COLOR._700,
            900: SUCCESS_COLOR._700,
          },
          'danger': {
            500: DANGER_COLOUR._500,
            600: DANGER_COLOUR._600,
            700: DANGER_COLOUR._700,
            800: DANGER_COLOUR._700,
            900: DANGER_COLOUR._700,
          }
        }
      }
    },
    'typography': {
      h1: {
        fontWeight: 400,
      },
      h2: {
        fontWeight: 400,
      },
      h3: {
        fontWeight: 400,
      },
      h4: {
        fontWeight: 400,
      },
      h5: {
        fontWeight: 400,
      },
      h6: {
        fontWeight: 400,
      }
    },
    'components': {
      JoyLink: {
        styleOverrides: {
          root: {
            color: badContrast && SYSTEM_COLOR.TEXT_BLACK,
            textDecorationColor: badContrast && SYSTEM_COLOR.TEXT_BLACK,
            '&:hover': {
              color: badContrast && colour,
              textDecorationColor: badContrast && colour,
            }
          }

        }
      },
      JoyButton: {
        styleOverrides: {
          root: {
            color: badContrast && CONTRAST_GRAY
          }
        }
      },
      JoyRadio: {
        styleOverrides: {
          root: {
            color: badContrast && CONTRAST_GRAY
          }
        }
      },
      JoyCheckbox: {
        styleOverrides: {
          root: {
            color: badContrast && CONTRAST_GRAY
          }
        }
      },
    }
  });

  return {
    joyTheme
  };
};
