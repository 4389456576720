import { useState } from 'react';
import { useIdleTimeout } from 'hooks';
import { DATE_NUMBERS } from 'lib/Enum';

export const useTimeoutModal = (props) => {
  const {
    timeout,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const timeoutSeconds = timeout ? timeout * DATE_NUMBERS.SECOND : undefined;

  useIdleTimeout(
    async () => {
      setModalOpen(true);
    },
    modalOpen ? undefined : timeoutSeconds
  );

  return { modalOpen, setModalOpen };
};
