import { forwardRef } from 'react';
import { IconWrapper } from 'icons/IconWrapper';

export const SVGConfidential = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-confidential'} {...props} viewBox={'0 0 41 41'}>
      <g stroke={'currentColor'} fill={'none'}>
        <path
          d={'M20.1667 39.3333C30.7521 39.3333 39.3333 30.7521 39.3333 20.1667C39.3333 9.58121 30.7521 1 20.1667 1C9.58121 1 1 9.58121 1 20.1667C1 30.7521 9.58121 39.3333 20.1667 39.3333Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M27.7058 27.6318C27.7058 28.0724 27.5313 28.4951 27.2206 28.8074C26.9098 29.1197 26.488 29.2963 26.0474 29.2985L14.3808 29.3518C13.9388 29.3518 13.5148 29.1762 13.2023 28.8637C12.8897 28.5511 12.7141 28.1272 12.7141 27.6852L12.6758 19.3518C12.6758 18.9098 12.8514 18.4859 13.1639 18.1733C13.4765 17.8608 13.9004 17.6852 14.3424 17.6852L26.0091 17.6318C26.4511 17.6318 26.8751 17.8074 27.1876 18.12C27.5002 18.4326 27.6758 18.8565 27.6758 19.2985L27.7058 27.6318Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M20.1818 21.8335C19.8525 21.8352 19.5311 21.9343 19.2581 22.1184C18.9851 22.3026 18.7728 22.5634 18.6479 22.8681C18.5231 23.1728 18.4912 23.5077 18.5565 23.8304C18.6217 24.1532 18.7811 24.4494 19.0145 24.6816C19.248 24.9139 19.5449 25.0718 19.868 25.1354C20.1911 25.199 20.5258 25.1656 20.8298 25.0392C21.1339 24.9128 21.3937 24.6992 21.5765 24.4253C21.7592 24.1514 21.8568 23.8295 21.8568 23.5002C21.8568 23.2806 21.8134 23.0632 21.7291 22.8604C21.6448 22.6577 21.5213 22.4736 21.3657 22.3187C21.21 22.1638 21.0253 22.0413 20.8221 21.958C20.619 21.8747 20.4013 21.8324 20.1818 21.8335V21.8335Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M15.9938 17.685L15.9805 15.185C15.9756 14.08 16.41 13.0182 17.1879 12.2334C17.9659 11.4486 19.0238 11.0049 20.1288 11C21.2339 10.9952 22.2957 11.4295 23.0805 12.2075C23.8653 12.9854 24.309 14.0433 24.3138 15.1484L24.3272 17.6484'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </g>
    </IconWrapper>
  );
});
