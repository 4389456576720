import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Bowser from 'bowser';

import 'whatwg-fetch';

import { isObject } from 'lib/Utils';

window.APP_ENV = (() => {
  switch(true) {
    case (/askyourteam.com/g).test(location.hostname): return 'production';
    case (/training-askyourteam.net/g).test(location.hostname): return 'training';
    case (/staging-askyourteam.net/g).test(location.hostname): return 'staging';
    default: return 'development';
  }
})();

const send = XMLHttpRequest.prototype.send;
XMLHttpRequest.prototype.send = function (...args) {
  this.loadstartTime = new Date().valueOf();
  return send.apply(this, args);
};

window.browserDetector = (function() {
  let p = Bowser.getParser(window.navigator.userAgent);
  let b = p.getResult();

  return {
    version: b.browser.version,

    tablet: b.platform.type == Bowser.PLATFORMS_MAP.tablet,
    mobile: b.platform.type == Bowser.PLATFORMS_MAP.mobile,

    msie: b.browser.name == Bowser.BROWSER_MAP.ie,
    msedge: b.browser.name == Bowser.BROWSER_MAP.edge,
    firefox: b.browser.name == Bowser.BROWSER_MAP.firefox,
    chrome: b.browser.name == Bowser.BROWSER_MAP.chrome,
    android: b.browser.name == Bowser.BROWSER_MAP.android,
    safari: b.browser.name == Bowser.BROWSER_MAP.safari,

    gecko: b.engine.name == Bowser.ENGINE_MAP.Gecko,

    ios: b.os.name == Bowser.OS_MAP.iOS,
    osversion: p.getOSVersion(),
    p: p,
    browser: b.browser,
    engine: b.engine,
    os: b.os,
    platform: b.platform,
  };
})();

// Convert from degrees to radians.
Math.radians = function(degrees) {
  return degrees * Math.PI / 180;
};

// Convert from radians to degrees.
Math.degrees = function(radians) {
  return radians * 180 / Math.PI;
};

!Array.prototype.distinct && Object.defineProperty(
  Array.prototype,
  'distinct',
  {
    enumerable: false,
    value: function(fn = x => x) {
      let arr = [...this];
      let set = new Set();
      return arr.filter(
        (item, i) => {
          // Worth noting that the mapping fn runs over the array once and in serial as if it were a map
          let key = fn(item, i, arr);
          if (set.has(key)) return false;
          set.add(key);
          return true;
        }
      );
    }
  }
);

!Array.prototype.sum && Object.defineProperty(
  Array.prototype,
  'sum',
  {
    enumerable: false,
    value: function(fn = x => x) {
      let arr = [...this];
      return arr.reduce(
        (sum, next, i, arr) => {
          let nextValue = fn(next, i, arr);
          if ({}.toString.call(nextValue) !== '[object Number]') return NaN;
          return sum + nextValue;
        },
        0
      );
    }
  }
);

!Array.prototype.pluck && Object.defineProperty(
  Array.prototype,
  'pluck',
  {
    enumerable: false,
    value: function(...fields) {
      let arr = [...this];
      if (fields.length == 0) return arr;
      return arr.map(
        obj => {
          if (fields.length == 1) return obj[fields[0]];
          return fields.reduce(
            (obj, field) => obj?.[field],
            obj
          );
        }
      );
    }
  }
);

!Array.prototype.object && Object.defineProperty(
  Array.prototype,
  'object',
  {
    enumerable: false,
    value: function(fn = x => x) {
      let arr = [...this];
      return arr.reduce(
        (obj, next, i, arr) => {
          let result = fn(next, i, arr);
          if (!isObject(result)) throw new Error('TypeError: Array.object expected result of object with { key: pair } to be merged');
          return { ...obj, ...result };
        },
        {}
      );
    }
  }
);
