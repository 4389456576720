import { useEffect } from 'react';

export const handleBeforeUnload = (event) => {
  event.preventDefault();
  event.returnValue = 'truthy';
};

/**
 * Triggers a browser-generated confirmation dialog that asks users to confirm if they really want to leave the page when they try to close or reload it, or navigate somewhere else. 
 * This is intended to help prevent loss of unsaved data.
 */
export const useLeavePageDialog = () => {
  return useEffect(
    () => {
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    },
    []
  );
};
