import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGAlertSolid = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-alert-solid'} {...props} >
      <g>
        <path d={'m23.76,20.46L13.87,1.58h0c-.54-1.03-1.82-1.43-2.86-.89-.38.2-.69.51-.89.89L.24,20.46c-.54,1.03-.14,2.31.89,2.85.3.16.64.24.98.24h19.77c1.17,0,2.12-.95,2.12-2.11,0-.34-.08-.68-.24-.98Z'} fill={'#f2d83b'} />
        <path d={'m10.94,8.21c0-.58.47-1.06,1.06-1.06s1.06.47,1.06,1.06v6.34c0,.58-.47,1.06-1.06,1.06s-1.06-.47-1.06-1.06v-6.34Z'} />
        <path d={'m12.05,20.39h-.03c-.87,0-1.58-.69-1.61-1.55-.03-.86.64-1.59,1.5-1.62,0,0,.02,0,.03,0h.03c.87,0,1.58.69,1.61,1.55.03.86-.64,1.59-1.5,1.62-.01,0-.02,0-.04,0Z'} />
      </g>
    </IconWrapper>
  );
});
