import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGExternalLink = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-external-link'} {...props} ariaLabel={'external-link'}>
      <title id={'external-link'}>External link</title>
      <g fill={'currentColor'}>
        <path d={'M23.85.15a.48.48,0,0,1,0,.7L8.58,16.13a.5.5,0,0,1-.71-.71L23.15.15A.48.48,0,0,1,23.85.15Z'} />
        <path d={'M23.85.15A.51.51,0,0,1,24,.5l0,9.77a.49.49,0,0,1-.5.5.5.5,0,0,1-.5-.5L23,1H13.72a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5H23.5A.47.47,0,0,1,23.85.15Z'} />
        <path d={'M19,11.05V22.4a.65.65,0,0,1-.65.65H1.65A.65.65,0,0,1,1,22.4V5.7a.65.65,0,0,1,.65-.65H12.91l1-1H1.65A1.65,1.65,0,0,0,0,5.7V22.4a1.65,1.65,0,0,0,1.65,1.65h16.7A1.65,1.65,0,0,0,20,22.4V10.08Z'} />
      </g>
    </IconWrapper>
  );
});
