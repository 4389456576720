import PropTypes from 'prop-types';
import classNames from 'classnames';
import { forwardRef } from 'react';

export const IconWrapper = forwardRef((props, ref) => {
  const {
    style,
    size,
    color,
    className,
    rotate,
    directional,
    ariaLabel,
    ariaHidden,
    ...rest
  } = props;

  return (
    <svg
      className={
        classNames(
          'svg-icon',
          className,
          {
            [`svg-icon--has-direction-${directional}`]: directional
          }
        )
      }
      ref={ref}
      style={{
        color,
        ...style,
        ...(rotate && { transform: `${style?.transform || ''} rotate(${rotate}deg)` })
      }}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={'0 0 24 24'}
      xmlns={'http://www.w3.org/2000/svg'}
      vectorEffect={'non-scaling-stroke'}
      aria-hidden={ariaHidden}
      shapeRendering={'geometricPrecision'}
      {...(ariaLabel ? { 'aria-labelledby': ariaLabel } : '')}
      {...rest}
    />
  );
});

IconWrapper.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  color: PropTypes.string,
  directional: PropTypes.oneOf(['ltr', 'rtl']),
  rotate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

IconWrapper.defaultProps = {
  size: 0
};
