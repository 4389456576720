// Vite Env Variables https://vite.dev/guide/env-and-mode.html#env-variables-and-modes
const {
  MODE: ENVIRONMENT,
  VITE_BUILDKITE_COMMIT: BUILDKITE_COMMIT
} = import.meta.env;

export {
  BUILDKITE_COMMIT,
  ENVIRONMENT
};
