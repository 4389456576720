import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagVI_VN = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-vi-VN'} {...props} >
      <circle cx={'12'} cy={'12'} r={'12'} fill={'#d80027'} />
      <path d={'m12,5.86l1.52,4.69h4.93l-3.99,2.9,1.52,4.69-3.99-2.89-3.99,2.9,1.53-4.69-3.99-2.91h4.93l1.52-4.69Z'} fill={'#ffda44'} />
    </IconWrapper>
  );
});
