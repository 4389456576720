import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagTO = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-to'} {...props} >
      <circle cx={'11.99'} cy={'12'} r={'11.99'} fill={'#f0f0f0'} />
      <path d={'M7.84,6.27V4.7H6.28V6.27H4.71V7.83H6.28V9.39H7.84V7.83H9.4V6.27Z'} fill={'#d80027'} />
      <path d={'M12,0V12H0A12,12,0,1,0,12,0Z'} fill={'#d80027'} />
    </IconWrapper>
  );
});
