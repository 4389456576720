import { useEffect, useRef } from 'react';
import idleTimeout from 'idle-timeout';

export const useIdleTimeout = (callback, timeout, props) => {
  const {
    element = document,
    loop = false
  } = props || {};

  const callbackRef = useRef();
  callbackRef.current = callback;

  useEffect(
    () => {
      if (!timeout) return;
      const instance = idleTimeout(
        () => {
          callbackRef.current();
        },
        {
          element,
          timeout,
          loop
        }
      );
      return () => {
        instance.destroy();
      };
    },
    [timeout, loop, element]
  );
};
