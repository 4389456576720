import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagPT_BR = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-pt-BR'} {...props} >
      <path d={'M12,0a12,12,0,0,0,0,24h0A12,12,0,0,0,12,0Z'} fill={'#009119'} />
      <path d={'M21,12l-.18.15-3.58,2.64-5,3.7a.27.27,0,0,1-.4,0L3.18,12.14C3,12,3,12,3.17,11.86l8.62-6.35a.28.28,0,0,1,.4,0l8.61,6.35Z'} fill={'#f8e000'} />
      <path d={'M16.23,12v0A4.23,4.23,0,0,0,8.4,9.78a10.8,10.8,0,0,1,1.79-.14A9.29,9.29,0,0,1,16.23,12Z'} fill={'#173099'} />
      <path d={'M9.27,11.38a7.36,7.36,0,0,0-1.47.29c0,.11,0,.22,0,.33A4.24,4.24,0,0,0,12,16.24h0A4.23,4.23,0,0,0,15.74,14,7.58,7.58,0,0,0,9.27,11.38Z'} fill={'#173099'} />
      <path d={'M10.19,9.64a10.8,10.8,0,0,0-1.79.14,4.21,4.21,0,0,0-.6,1.89,7.36,7.36,0,0,1,1.47-.29A7.58,7.58,0,0,1,15.74,14,4.11,4.11,0,0,0,16.23,12,9.29,9.29,0,0,0,10.19,9.64Z'} fill={'#efefef'} />
    </IconWrapper>
  );
});
