import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGError = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-error'} {...props} >
      <g fill={'currentColor'}>
        <path d={'M12 24C5.37 24 0 18.63 0 12S5.37 0 12 0s12 5.37 12 12-5.37 12-12 12Zm0-22.96C5.95 1.04 1.04 5.95 1.04 12c0 6.05 4.91 10.96 10.96 10.96 6.05 0 10.96-4.91 10.96-10.96 0-6.06-4.91-10.96-10.96-10.96Z'} />
        <path d={'M17.57 18c-.11 0-.22-.04-.3-.13L6.13 6.73c-.17-.17-.17-.44 0-.61s.44-.17.61 0l11.14 11.14c.17.17.17.44 0 .61-.08.08-.19.13-.3.13Z'} />
        <path d={'M6.43 18c-.11 0-.22-.04-.3-.13a.429.429 0 0 1 0-.61L17.27 6.12c.17-.17.44-.17.61 0s.17.44 0 .61L6.74 17.87c-.08.08-.19.13-.3.13Z'} />
      </g>
    </IconWrapper>
  );
});
