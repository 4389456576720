import { useMemo, useCallback } from 'react';
import { Option, Select, Stack, Typography } from '@mui/joy';
import { useLocale } from 'hooks';

import { LOCALE_CODES } from 'lib/Enum';
import { LocaleFlags } from 'icons';

const SelectedValue = ({ selected, options }) => {
  if (!selected) {
    return null;
  }
  const { value: selectedValue, label } = selected;

  const icon = options.find(({ value }) => value === selectedValue)?.icon;

  return (
    <Stack direction={'row'} spacing={1}>
      {icon}
      <Typography
        data-cy={'intro-language-picker-value'}
        maxWidth={{ sm: 68, md: 141 }}
        noWrap
        sx={{ display: { xs: 'none', sm: 'block' } }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export const LocaleSelector = (props) => {
  const { locales } = props;

  const [locale, , { updateRespondentLocale }] = useLocale();

  const localeOptions = useMemo(
    () => Object.entries(LOCALE_CODES)
      .filter(([localeName]) => locales.includes(localeName))
      .map(
        ([value, label]) => {
          const Flag = LocaleFlags(value);
          return {
            ...Flag && ({ icon: <Flag size={24} ariaHidden={'true'} /> }),
            value,
            label
          };
        }
      ),
    [locales]
  );

  const onChange = useCallback(
    (e, newValue) => {
      updateRespondentLocale(newValue);
    },
    [updateRespondentLocale]
  );

  return (
    <Select
      data-component={'select-language'}
      name={'select-language'}
      aria-label={'Select language'}
      sx={{
        px: 1,
        width: { xs: 'auto', sm: 120, md: 220 }
      }}
      value={locale}
      onChange={onChange}
      renderValue={
        (selected) => <SelectedValue selected={selected} options={localeOptions} />
      }
      data-cy={'intro-language-picker'}
    >
      {localeOptions.map(({ value, label, icon }) => (
        <Option
          data-cy={`intro-language-picker-${value}`}
          key={value}
          value={value}
          label={label}
          data-key={value}
        >
          {icon}
          {label}
        </Option>
      ))}
    </Select>
  );
};
