import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagTL_PH = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-tl-PH'} {...props} >
      <path d={'M12.21,0c-.14,.07-.28,.08-.42,0h.42Z'} fill={'#fda128'} />
      <path d={'M12.21,0c-.14,.08-.28,.07-.42,0h.42Z'} fill={'#fda128'} />
      <path d={'M11.79,24.02c.14-.08,.28-.07,.42,0h-.42Z'} fill={'#7bad57'} />
      <path d={'M11.81,24.02c.12-.07,.25-.07,.37,0h-.37Z'} fill={'#7bad57'} />
      <path d={'M12.07,0c-.05,.07-.09,.06-.14,0h.14Z'} fill={'#fac681'} />
      <path d={'M12.05,0c-.03,.05-.06,.06-.09,0h.09Z'} fill={'#fac886'} />
      <circle cx={'12'} cy={'12.01'} r={'11.99'} fill={'#fff'} />
      <path d={'M12.04,12l.02,.02L3.6,20.47c2.16,2.16,5.15,3.5,8.45,3.5,6.6,0,11.95-5.35,11.95-11.95v-.02H12.04Z'} fill={'#d80027'} />
      <path d={'M12.04,12l.02-.02L3.6,3.53C5.77,1.36,8.75,.02,12.06,.02,18.66,.02,24.01,5.38,24.01,11.98v.02H12.04Z'} fill={'#0052b3'} />
      <polygon points={'3.57 6.1 3.93 6.55 4.5 6.5 4.19 6.98 4.41 7.51 3.85 7.36 3.42 7.73 3.39 7.16 2.9 6.87 3.43 6.66 3.57 6.1'} fill={'#fed944'} />
      <polygon points={'3.57 16.73 3.93 17.17 4.5 17.12 4.19 17.6 4.41 18.13 3.85 17.99 3.42 18.36 3.39 17.79 2.9 17.49 3.43 17.29 3.57 16.73'} fill={'#fed944'} />
      <polygon points={'9.33 11.29 9.48 11.85 10.01 12.04 9.53 12.35 9.51 12.92 9.07 12.55 8.52 12.71 8.73 12.18 8.41 11.7 8.98 11.74 9.33 11.29'} fill={'#fed944'} />
      <polygon points={'6.41 14.61 4.64 13.75 2.93 14.72 3.2 12.77 1.75 11.43 3.69 11.09 4.51 9.3 5.43 11.04 7.39 11.26 6.02 12.68 6.41 14.61'} fill={'#fed944'} />
      <polygon points={'6.16 9.73 6.03 11.7 7.58 12.92 5.67 13.4 4.98 15.25 3.93 13.58 1.96 13.5 3.23 11.99 2.69 10.09 4.52 10.82 6.16 9.73'} fill={'#fed944'} />
    </IconWrapper>
  );
});
