import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagEN = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-en'} {...props} >
      <circle cx={'11.93'} cy={'12'} r={'11.93'} fill={'#f0f0f0'} />
      <path d={'M2.6,4.73A12,12,0,0,0,.54,8.89H6.75Z'} fill={'#0052b4'} />
      <path d={'M23.59,8.89a12,12,0,0,0-2.06-4.16L17.38,8.89Z'} fill={'#0052b4'} />
      <path d={'M.54,15.11A12,12,0,0,0,2.6,19.27l4.15-4.16Z'} fill={'#0052b4'} />
      <path d={'M19.33,2.53A12,12,0,0,0,15.18.47V6.69Z'} fill={'#0052b4'} />
      <path d={'M4.8,21.47A12,12,0,0,0,9,23.53V17.32Z'} fill={'#0052b4'} />
      <path d={'M9,.48A11.77,11.77,0,0,0,4.8,2.53L9,6.69Z'} fill={'#0052b4'} />
      <path d={'M15.18,23.52a11.77,11.77,0,0,0,4.15-2.05l-4.15-4.16Z'} fill={'#0052b4'} />
      <path d={'M17.38,15.11l4.15,4.16a11.67,11.67,0,0,0,2.06-4.16Z'} fill={'#0052b4'} />
      <path d={'M23.9,10.44H13.62V.17a11.57,11.57,0,0,0-3.11,0V10.44H.23a12.53,12.53,0,0,0,0,3.12H10.51V23.83a11.57,11.57,0,0,0,3.11,0V13.56H23.9A12.53,12.53,0,0,0,23.9,10.44Z'} fill={'#d80027'} />
      <path d={'M15.18,15.11h0l5.32,5.33a10.13,10.13,0,0,0,.7-.77l-4.56-4.56Z'} fill={'#d80027'} />
      <path d={'M9,15.11H9L3.63,20.44c.24.24.5.48.76.7L9,16.58Z'} fill={'#d80027'} />
      <path d={'M9,8.89H9L3.63,3.56a10.13,10.13,0,0,0-.7.77L7.48,8.89Z'} fill={'#d80027'} />
      <path d={'M15.18,8.89h0L20.5,3.56c-.24-.24-.5-.48-.76-.7L15.18,7.42Z'} fill={'#d80027'} />
    </IconWrapper>
  );
});
