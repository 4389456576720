import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagMI = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-mi'} {...props} >
      <path d={'M9.91,10.8A3.19,3.19,0,0,1,7.15,14a3.44,3.44,0,0,1-.45,0,5.42,5.42,0,0,0,0-10.84H3.89a12,12,0,0,0-3,4.42H6.7A3.21,3.21,0,0,1,9.91,10.8Z'} fill={'#fff'} />
      <path d={'M6.7,14a3.44,3.44,0,0,0,.45,0A2.21,2.21,0,0,0,6.7,9.59h0a4.42,4.42,0,0,0,0,8.83H22.13A12,12,0,0,0,23.82,14Z'} fill={'#fff'} />
      <path d={'M12.12,8.59A5.42,5.42,0,0,1,6.7,14H23.82A11.94,11.94,0,0,0,12,0,12,12,0,0,0,3.89,3.17H6.7A5.42,5.42,0,0,1,12.12,8.59Z'} />
      <path d={'M2.29,14A4.41,4.41,0,0,1,6.7,9.59h0A2.21,2.21,0,0,1,7.15,14,3.2,3.2,0,0,0,6.7,7.59H.85A12,12,0,0,0,22.13,18.42H6.7A4.41,4.41,0,0,1,2.29,14Z'} fill={'#d40000'} />
    </IconWrapper>
  );
});
