import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGTickCircle = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-tick-circle'} {...props} >
      <g fill={'currentColor'} >
        <path d={'M12,24A12,12,0,1,1,24,12,12,12,0,0,1,12,24ZM12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Z'} />
        <path d={'M9.57,16.19a1.43,1.43,0,0,1-1-.43L6.3,13.53a.5.5,0,0,1,0-.71.5.5,0,0,1,.7,0l2.23,2.23a.48.48,0,0,0,.67,0L17,8a.49.49,0,1,1,.7.7l-7.09,7.1A1.46,1.46,0,0,1,9.57,16.19Z'} />
      </g>
    </IconWrapper>
  );
});
