import { OAuth } from 'lib/OAuth';

export const getAPI = (url) => {
  return new OAuth().getAPI(`${OAuth.API_DOMAIN}/${url}`)
    .then(res => res);
};

export const postAPI = (url, params) => {
  return new OAuth().postAPI(`${OAuth.API_DOMAIN}/${url}`, { ...params })
    .then(res => res);
};

export const putAPI = (url, params) => {
  return new OAuth().putAPI(`${OAuth.API_DOMAIN}/${url}`, { ...params })
    .then(res => res);
};
