import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGQuestionMark = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-question-mark'} {...props} viewBox={'0 0 16 16'}>
      <g fill={'currentColor'} >
        <path d={'M8 16C6.41775 16 4.87103 15.5308 3.55544 14.6518C2.23985 13.7727 1.21447 12.5233 0.608967 11.0615C0.00346629 9.59966 -0.15496 7.99113 0.153721 6.43928C0.462403 4.88743 1.22433 3.46197 2.34315 2.34315C3.46197 1.22433 4.88743 0.462403 6.43928 0.153721C7.99113 -0.15496 9.59966 0.00346629 11.0615 0.608967C12.5233 1.21447 13.7727 2.23985 14.6518 3.55544C15.5308 4.87104 16 6.41775 16 8C15.9982 10.1212 15.1548 12.155 13.6549 13.6549C12.155 15.1548 10.1212 15.9982 8 16ZM8 0.66667C6.54961 0.66667 5.13178 1.09676 3.92582 1.90256C2.71986 2.70836 1.77993 3.85367 1.22489 5.19366C0.669844 6.53365 0.52462 8.00814 0.807578 9.43067C1.09054 10.8532 1.78897 12.1599 2.81455 13.1855C3.84014 14.211 5.14681 14.9095 6.56934 15.1924C7.99187 15.4754 9.46636 15.3302 10.8063 14.7751C12.1463 14.2201 13.2916 13.2801 14.0974 12.0742C14.9032 10.8682 15.3333 9.4504 15.3333 8C15.3333 6.05508 14.5607 4.18982 13.1855 2.81455C11.8102 1.43929 9.94492 0.66667 8 0.66667Z'} />
        <path d={'M8.00159 11.8867C7.86784 11.888 7.73881 11.8373 7.64177 11.7452C7.54473 11.6532 7.48729 11.527 7.48159 11.3934C7.4769 11.3257 7.48699 11.2579 7.51117 11.1946C7.53534 11.1313 7.57302 11.074 7.62159 11.0267C7.66663 10.9762 7.72225 10.9363 7.78447 10.9098C7.8467 10.8833 7.914 10.8709 7.98159 10.8734H8.02825C8.15194 10.8838 8.26758 10.939 8.35348 11.0286C8.43939 11.1182 8.48968 11.236 8.49492 11.36C8.49508 11.4973 8.44533 11.63 8.35492 11.7334C8.25872 11.8243 8.13368 11.8785 8.00159 11.8867Z'} />
        <path d={'M8.00016 10.3337C7.91176 10.3337 7.82697 10.2985 7.76446 10.236C7.70195 10.1735 7.66683 10.0887 7.66683 10.0003V9.72699C7.66379 9.40129 7.7571 9.08196 7.93503 8.80914C8.11295 8.53632 8.36755 8.32219 8.66683 8.19365C9.07197 8.01496 9.38976 7.68297 9.55059 7.27042C9.71142 6.85786 9.70218 6.39838 9.52488 5.99263C9.34759 5.58688 9.0167 5.26794 8.6047 5.10569C8.1927 4.94343 7.73319 4.95109 7.32683 5.12699C7.0274 5.2534 6.77238 5.46611 6.59429 5.738C6.41619 6.00989 6.32308 6.32865 6.32683 6.65365C6.32546 6.74163 6.2899 6.82562 6.22768 6.88784C6.16546 6.95005 6.08147 6.98561 5.9935 6.98699C5.90509 6.98699 5.82031 6.95187 5.7578 6.88936C5.69528 6.82684 5.66016 6.74206 5.66016 6.65365C5.65937 6.34701 5.71919 6.04324 5.83617 5.75979C5.95315 5.47634 6.12499 5.2188 6.34182 5.00197C6.55864 4.78514 6.81618 4.6133 7.09963 4.49632C7.38308 4.37934 7.68686 4.31953 7.9935 4.32032C8.61234 4.32032 9.20583 4.56615 9.64341 5.00374C10.081 5.44132 10.3268 6.03482 10.3268 6.65365C10.3257 7.1076 10.1928 7.55145 9.94426 7.93132C9.69575 8.3112 9.3423 8.61076 8.92683 8.79365C8.74658 8.86983 8.59319 8.99809 8.48629 9.162C8.3794 9.32591 8.32387 9.51799 8.32683 9.71365V9.98699C8.32545 10.075 8.2899 10.159 8.22768 10.2212C8.16546 10.2834 8.08147 10.3189 7.9935 10.3203L8.00016 10.3337Z'} />
      </g>
    </IconWrapper>
  );
});
