import { useTranslation } from 'react-i18next';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { getAPI, putAPI } from 'lib/APIHelpers';

import { useMemoObject } from './useMemoObject';

const Store = createContext();

export const LocaleProvider = ({ children }) => {
  const { i18n } = useTranslation();
  // "i18n.language" gets the current language code.
  // Fallback language is 'en' which is declared in src/i18n.js
  const defaultLocale = i18n.language;
  const { feedbackID } = useParams();

  const [locale, setLocale] = useState(defaultLocale);
  const [localeUpdating, setLocaleUpdating] = useState(false);

  const onLocalChange = useCallback(
    async locale => {
      if (!locale) return;
      i18n.changeLanguage(locale);
      // Update the locale state so components using this value from the store get the new value.
      setLocale(locale);
    },
    [i18n, setLocale]
  );

  const updateRespondentLocale = useCallback(
    async (locale) => {
      if (!locale) return;
      setLocaleUpdating(true);
      onLocalChange(locale);

      if (feedbackID) {
        await putAPI(
          `api/v2/feedback/${feedbackID}/update_respondent`,
          { respondent: { locale } }
        );
      }
      setLocaleUpdating(false);
    },
    [feedbackID, onLocalChange]
  );

  useEffect(
    () => {
      if (!feedbackID) return;
      // Get the locale data for the respondent from the API and set it
      // so the i18n components can use it and we can also use it via this hook/store.
      getAPI(`api/v2/feedback/${feedbackID}/locales_available`)
        .then(({ respondent = {} }) => {
          if (respondent && respondent.locale) {
            onLocalChange(respondent.locale);
          }
        });
    },
    [feedbackID, onLocalChange]
  );

  const value = useMemoObject({
    locale,
    localeUpdating,
    setLocale: onLocalChange,
    updateRespondentLocale
  });
  return (
    <Store.Provider value={value}>
      {children}
    </Store.Provider>
  );
};

export const useLocale = () => {
  const { locale, setLocale, updateRespondentLocale, localeUpdating } = useContext(Store);
  return [locale, setLocale, { updateRespondentLocale, localeUpdating }];
};
