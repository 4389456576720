import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGPadlock = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-padlock'} {...props} >
      <g fill={'currentColor'} >
        <path d={'M17.5,10a.5.5,0,0,1-.5-.5V6A5,5,0,0,0,7,6,.5.5,0,0,1,6,6,6,6,0,0,1,18,6V9.5A.5.5,0,0,1,17.5,10Z'} />
        <path d={'M18.5,24H5.5A2.5,2.5,0,0,1,3,21.5v-10A2.5,2.5,0,0,1,5.5,9h13A2.5,2.5,0,0,1,21,11.5v10A2.5,2.5,0,0,1,18.5,24ZM5.5,10A1.5,1.5,0,0,0,4,11.5v10A1.5,1.5,0,0,0,5.5,23h13A1.5,1.5,0,0,0,20,21.5v-10A1.5,1.5,0,0,0,18.5,10Z'} />
        <path d={'M12,18.25a1.75,1.75,0,1,1,1.75-1.75A1.76,1.76,0,0,1,12,18.25Zm0-2.5a.75.75,0,1,0,.75.75A.76.76,0,0,0,12,15.75Z'} />
      </g>
    </IconWrapper>
  );
});
