import { useCallback } from 'react';
import { getAPI } from 'lib/APIHelpers';
import { hasLocalStorageAvailable } from 'lib/Utils';
import { handleBeforeUnload } from './useLeavePageDialog';

export const useResetKiosk = ({ feedbackID, survey_uuid }) => {

  const onResetKiosk = useCallback(
    async () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);

      const { redirect_url } = await getAPI(
        `api/v2/feedback/${feedbackID}/kiosk_reset?survey_uuid=${survey_uuid}`
      );

      if (hasLocalStorageAvailable) {
        localStorage.removeItem(`session_token_${feedbackID}`);
      }

      if (redirect_url) {
        return window.location.assign(redirect_url);
      }
    },
    [feedbackID, survey_uuid]
  );

  return { onResetKiosk };
};
