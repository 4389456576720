import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

import './loading.scss';

export const SVGLoadingSpinner = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-loading-spinner'} {...props} >
      <g
        fill={'currentColor'}
        className={'loading--svg-spinner'}
        transform={'translate(12, 12)'}
      >
        <circle cx={-6.95} cy={-6.95} r={2} />
        <circle cx={-9.8} cy={0} r={2} />
        <circle cx={-6.95} cy={6.95} r={2} />
        <circle cx={0} cy={9.8} r={2} />
        <circle cx={6.95} cy={6.95} r={2} />
        <circle cx={9.8} cy={0} r={2} />
        <circle cx={6.95} cy={-6.95} r={2} />
        <circle cx={0} cy={-9.8} r={2} />
      </g>
    </IconWrapper>
  );
});
