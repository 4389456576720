import { animated as a } from 'react-spring';

const blink = time => {
  const mills = time % 1000;
  return Math.abs(mills - 500) / 500;
};

export const CircleBar = (props) => {
  const {
    size,
    value,
    timeout
  } = props;

  const center = (size/2);
  const radius = center - 4;
  const circumference = 2 * Math.PI * radius;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns={'http://www.w3.org/2000/svg'}
      className={'progress-countdown-timer__svg'}
      aria-hidden={true}
      transform={'rotate(90) scale(-1, 1)'}
    >
      <a.g opacity={value.to(time => time > 10000 ? 1: blink(time))}>
        <circle
          className={'progress-countdown-timer__svg-background'}
          r={radius}
          cx={center}
          cy={center}
        />
        <a.circle
          className={'progress-countdown-timer__svg-foreground'}
          r={radius}
          cx={center}
          cy={center}
          strokeDasharray={circumference}
          strokeDashoffset={value.to(time => (((timeout - time) / timeout) * circumference))}
        />
      </a.g>
    </svg>
  );
};
