import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagEN_AU = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-en-AU'} {...props} >
      <path d={'M12,0a12,12,0,0,0,0,24h0A12,12,0,0,0,12,0Z'} fill={'#273375'} />
      <polygon points={'8.23 18.39 9.04 17.39 7.79 17.61 7.36 16.4 6.88 17.61 5.61 17.39 6.42 18.37 5.58 19.36 6.86 19.16 7.29 20.34 7.76 19.16 9.07 19.39 8.23 18.39'} fill={'#f3f4f5'} />
      <polygon points={'17.8 19.29 18.25 18.71 17.55 18.84 17.3 18.16 17.03 18.84 16.31 18.71 16.77 19.27 16.29 19.83 17.02 19.72 17.26 20.39 17.53 19.72 18.27 19.85 17.8 19.29'} fill={'#f3f4f5'} />
      <polygon points={'17.8 6.66 18.25 6.08 17.55 6.21 17.3 5.53 17.03 6.21 16.31 6.08 16.77 6.64 16.29 7.2 17.02 7.09 17.26 7.76 17.53 7.09 18.27 7.22 17.8 6.66'} fill={'#f3f4f5'} />
      <polygon points={'20.76 9.92 21.22 9.35 20.51 9.48 20.27 8.79 20 9.48 19.28 9.35 19.74 9.91 19.26 10.47 19.99 10.35 20.23 11.03 20.5 10.35 21.24 10.49 20.76 9.92'} fill={'#f3f4f5'} />
      <polygon points={'15.23 11.38 15.69 10.8 14.98 10.93 14.73 10.25 14.46 10.93 13.74 10.8 14.2 11.36 13.72 11.92 14.45 11.81 14.7 12.48 14.96 11.81 15.71 11.94 15.23 11.38'} fill={'#f3f4f5'} />
      <path d={'M2.52,4.65Q2.24,5,2,5.4H3.27Z'} fill={'#29337a'} />
      <polygon points={'8.72 12 9.87 12 8.72 10.84 8.72 12'} fill={'#29337a'} />
      <path d={'M4.89,2.34a10.19,10.19,0,0,0-.8.64l.8.8Z'} fill={'#29337a'} />
      <polygon points={'3.73 12 4.88 12 4.88 10.84 3.73 12'} fill={'#29337a'} />
      <path d={'M12,0A11.81,11.81,0,0,0,8.72.46V3.78L12,.5V0Z'} fill={'#29337a'} />
      <polygon points={'12 10.89 12 9.23 10.33 9.23 12 10.89'} fill={'#29337a'} />
      <path d={'M.5,12,3.27,9.23H.33A11.54,11.54,0,0,0,0,12Z'} fill={'#29337a'} />
      <polygon points={'10.33 5.39 12 5.39 12 3.73 10.33 5.39'} fill={'#29337a'} />
      <polygon points={'12 11.24 12 10.89 10.33 9.23 12 9.23 12 8.63 9.39 8.63 12 11.24'} fill={'#fff'} />
      <path d={'M3.27,5.4H2c-.13.19-.25.39-.37.59H4.14l-1.5-1.5-.12.16Z'} fill={'#fff'} />
      <polygon points={'4.88 10.84 4.88 12 5.48 12 5.48 9.97 3.45 12 3.73 12 4.88 10.84'} fill={'#fff'} />
      <path d={'M8.72.46l-.6.18v4L12,.77V.5L8.72,3.78Z'} fill={'#fff'} />
      <path d={'M4.86,9.23l0,0h0l.57-.58h-5c0,.2-.11.4-.15.6H3.27L.5,12H2.09L4.86,9.23Z'} fill={'#fff'} />
      <path d={'M.5,12,3.27,9.23Z'} fill={'#fff'} />
      <polygon points={'12 5.99 12 5.39 10.33 5.39 12 3.73 12 2.13 8.14 5.99 12 5.99'} fill={'#fff'} />
      <path d={'M12,3.73,10.33,5.4Z'} fill={'#fff'} />
      <path d={'M4.89,3.78,4.09,3a10.07,10.07,0,0,0-.81.79l1.61,1.6h0v0L5.48,6v-4c-.2.13-.4.26-.59.41V3.78Z'} fill={'#fff'} />
      <polygon points={'8.72 10.84 9.87 12 11.4 12 8.12 8.72 8.12 12 8.72 12 8.72 10.84'} fill={'#fff'} />
      <path d={'M4.89,5.37v0Z'} fill={'#d32030'} />
      <path d={'M10,9.23l-.59-.6H12V6H8.14l0,0V6h0L12,2.13V.77L8.12,4.65v-4A12.43,12.43,0,0,0,5.48,1.93V6l0,0h0V6L4.9,5.39h0v0L3.28,3.77a8.67,8.67,0,0,0-.64.72L4.14,6H1.61A12.88,12.88,0,0,0,.48,8.63h5l0,0v0h0l-.57.58v0h0L2.09,12H3.45l2-2v2H8.12V8.72L11.4,12h.45l.15-.16v-.6Z'} fill={'#d32030'} />
      <polygon points={'8.12 4.65 12 0.77 12 0.77 8.12 4.65'} fill={'#d32030'} />
      <polygon points={'4.88 9.23 4.88 9.21 4.86 9.23 4.88 9.23'} fill={'#d32030'} />
      <polygon points={'5.48 8.61 5.46 8.63 5.48 8.63 5.48 8.61'} fill={'#d32030'} />
      <polygon points={'8.12 6.01 8.14 5.99 8.12 5.99 8.12 6.01'} fill={'#d32030'} />
      <polygon points={'4.88 5.39 4.91 5.39 4.9 5.39 4.88 5.38 4.88 5.39'} fill={'#d32030'} />
      <polygon points={'5.5 5.99 5.48 5.97 5.48 5.99 5.5 5.99'} fill={'#d32030'} />
    </IconWrapper>
  );
});
