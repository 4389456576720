import { useTranslation } from 'react-i18next';
import { Link, Stack, } from '@mui/joy';
import { SVGExternalLink, SVGLogo } from 'icons';

const FONT_SIZE = 'body-xs';

export const PageFooter = ({ is_kiosk_mode }) => {
  const { t } = useTranslation();
  
  return (
    <footer className={'app__footer'}>
      <Stack
        spacing={{ xs: 1, md: 3 }}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <SVGLogo size={32} />

        {!is_kiosk_mode && (
          <>
            <Link
              underline={'always'}
              level={FONT_SIZE}
              href={'https://www.askyourteam.com/askyourteam-app-privacy-policy'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {t('Privacy policy')}
              <SVGExternalLink
                style={{ marginLeft: 8 }}
                size={12}
              />
            </Link>
            <Link
              ml={2}
              underline={'always'}
              level={FONT_SIZE}
              href={'https://stonly.com/sl/en/89552a84-c918-4dc0-9b11-10fa86dc1f4c/Steps/1815324'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {t('Frequently asked questions')}
              <SVGExternalLink
                style={{ marginLeft: 8 }}
                size={12}
              />
            </Link>
          </>
        )}
      </Stack>
    </footer>
  );
};
