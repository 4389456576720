import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagFR = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-fr'} {...props} >
      <circle cx={'11.95'} cy={'12'} r={'11.95'} fill={'#f0f0f0'} />
      <path d={'M24,12A12,12,0,0,0,16.21.8V23.2A12,12,0,0,0,24,12Z'} fill={'#d80027'} />
      <path d={'M.11,12A11.94,11.94,0,0,0,7.9,23.2V.8A12,12,0,0,0,.11,12Z'} fill={'#0052b4'} />
    </IconWrapper>
  );
});
