import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagPIS = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-pis'} {...props} >
      <path d={'M20.48,20.48A12,12,0,0,0,21,4.09L12,12,4.09,21A12,12,0,0,0,20.48,20.48Z'} fill={'#496e2d'} />
      <path d={'M3.52,3.51A12,12,0,0,1,19.91,3L12,12,3,19.91A12,12,0,0,1,3.52,3.51Z'} fill={'#0052b4'} />
      <path d={'M19.91,3,3,19.91c.17.2.35.39.53.58s.38.36.58.53L21,4.09c-.17-.2-.35-.39-.53-.58S20.11,3.15,19.91,3Z'} fill={'#ffda44'} />
      <path d={'M5.06,4.17l.26.8h.83l-.67.49.25.8-.67-.49-.68.49.26-.8L4,5H4.8Z'} fill={'#f0f0f0'} />
      <path d={'M9.34,4.17,9.6,5h.84l-.68.49.26.8-.68-.49-.68.49.26-.8L8.24,5h.84Z'} fill={'#f0f0f0'} />
      <path d={'M5.06,8.35l.26.8h.83l-.67.49.25.8-.67-.5-.68.5.26-.8L4,9.15H4.8Z'} fill={'#f0f0f0'} />
      <path d={'M9.34,8.35l.26.8h.84l-.68.49.26.8-.68-.5-.68.5.26-.8-.68-.49h.84Z'} fill={'#f0f0f0'} />
      <path d={'M7.2,6.26l.26.8h.83l-.67.49.26.8L7.2,7.86l-.68.49.26-.8L6.1,7.06h.84Z'} fill={'#f0f0f0'} />
    </IconWrapper>
  );
});
