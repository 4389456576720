import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagES_MX = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-es-MX'} {...props} >
      <circle cx={'11.92'} cy={'12'} r={'11.92'} fill={'#f0f0f0'} />
      <path d={'M24,12A11.91,11.91,0,0,0,17.26,1.27V22.73A11.9,11.9,0,0,0,24,12Z'} fill={'#d80027'} />
      <path d={'M.16,12A11.91,11.91,0,0,0,6.9,22.73V1.27A11.91,11.91,0,0,0,.16,12Z'} fill={'#006647'} />
      <path d={'M9,12a3.11,3.11,0,0,0,6.22,0h0V11H9Z'} fill={'#006647'} />
      <path d={'M16.23,9.93H13.12a1,1,0,1,0-2.07,0H7.94A1.05,1.05,0,0,0,9,11H9a1,1,0,0,0,1,1h0a1,1,0,0,0,1,1h2.07a1,1,0,0,0,1-1h0a1,1,0,0,0,1-1h0A1.07,1.07,0,0,0,16.23,9.93Z'} fill={'#ff9811'} />
    </IconWrapper>
  );
});
