import { SVGLoadingSpinner } from 'icons/loading';
import { SYSTEM_COLOR } from 'lib/Enum';

export const AppSkeleton = () => (
  <div className={'app'} data-component={'app-loading'}>
    <div className={'app__loading'} >
      <SVGLoadingSpinner color={SYSTEM_COLOR.PRIMARY} size={40} style={{ margin: 'auto' }} />
    </div>
  </div>
);
