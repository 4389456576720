import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGNext = forwardRef((props, ref) => {
  return (
    <IconWrapper
      ref={ref}
      data-component={'svg-next'}
      {...props}
      directional={'ltr'}
    >
      <g fill={'currentColor'} >
        <path d={'M24 12c0 6.63-5.37 12-12 12S0 18.63 0 12 5.37 0 12 0s12 5.37 12 12ZM1.04 12c0 6.05 4.91 10.96 10.96 10.96 6.05 0 10.96-4.91 10.96-10.96 0-6.05-4.91-10.96-10.96-10.96C5.95 1.04 1.04 5.95 1.04 12Z'} />
        <path d={'M17.04 12c0 .28-.22.5-.5.5H7.46c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9.07c.28 0 .5.22.5.5Z'} />
        <path d={'M17.03 12c0 .13-.05.26-.15.35l-4.54 4.53c-.2.19-.51.19-.71 0-.19-.2-.2-.51 0-.71l4.19-4.18-4.19-4.18c-.2-.2-.2-.51 0-.71.19-.2.51-.2.71 0l4.54 4.53c.09.09.15.22.15.35Z'} />
      </g>
    </IconWrapper>
  );
});
