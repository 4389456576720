import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagES_CL = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-es-CL'} {...props} >
      <circle cx={'12'} cy={'12'} r={'12'} fill={'#f0f0f0'} />
      <path d={'M24,12A12,12,0,0,1,0,12c0-6.63,12,0,12,0Z'} fill={'#d80027'} />
      <path d={'M0,12A12,12,0,0,1,12,0V12Z'} fill={'#0052b4'} />
      <path d={'M7.14,4.17l.78,2.4h2.51L8.4,8l.78,2.39L7.14,9l-2,1.47L5.89,8l-2-1.48H6.37Z'} fill={'#f0f0f0'} />
    </IconWrapper>
  );
});
