import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGCaution = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-caution'} {...props} >
      <g fill={'currentColor'} >
        <path d={'M1.13,24a1.12,1.12,0,0,1-.85-1.77L11.07.64a1,1,0,0,1,1.86,0L23.74,22.27a1.11,1.11,0,0,1-.27,1.51,1.09,1.09,0,0,1-.83.2H1.13Zm21.59-1,.45.4L22.88,23a.11.11,0,0,0,0-.15.43.43,0,0,1,0-.07L12,1.09,12,1l0,.05L1.14,22.75l-.06.15A.11.11,0,0,0,1.2,23Z'} />
        <path d={'M12,17a.5.5,0,0,1-.5-.51V9a.5.5,0,0,1,1,0v7.5A.5.5,0,0,1,12,17Z'} />
        <path d={'M12,19.71a.78.78,0,0,1-.79-.75.77.77,0,0,1,.75-.8h0a.79.79,0,0,1,.75.71v.06A.77.77,0,0,1,12,19.71'} />
      </g>
    </IconWrapper>
  );
});
