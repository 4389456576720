import './draftjs-html-render.scss';

export const WYSIWYGRenderer = ({ template }) => {
  return (
    <div
      className={'draftjs-html-render'}
      data-cy={'wysiwyg-content'}
      style={{ maxWidth: 720 }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: template }}
    />
  );
};

export default WYSIWYGRenderer;
