import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagID = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-id'} {...props} >
      <g clipPath={'url(#clip0_3_84)'}>
        <rect width={'24'} height={'12'} fill={'#FF0000'} />
        <rect y={'12'} width={'24'} height={'12'} fill={'#F0F0F0'} />
      </g>
      <defs>
        <clipPath id={'clip0_3_84'}>
          <rect width={'24'} height={'24'} rx={'12'} fill={'white'} />
        </clipPath>
      </defs>
    </IconWrapper>
  );
});
