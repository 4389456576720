import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGDemographic = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-demographic'} {...props} viewBox={'0 0 42 42'} >
      <g stroke={'currentColor'} fill={'none'}>
        <path
          d={'M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M17.0859 28.4473H24.3142'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M12.9547 32.5759C15.2358 32.5759 17.0851 30.7267 17.0851 28.4455C17.0851 26.1643 15.2358 24.3151 12.9547 24.3151C10.6735 24.3151 8.82422 26.1643 8.82422 28.4455C8.82422 30.7267 10.6735 32.5759 12.9547 32.5759Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M28.4429 32.5759C30.7241 32.5759 32.5734 30.7267 32.5734 28.4455C32.5734 26.1643 30.7241 24.3151 28.4429 24.3151C26.1618 24.3151 24.3125 26.1643 24.3125 28.4455C24.3125 30.7267 26.1618 32.5759 28.4429 32.5759Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M20.7007 17.0868C22.9819 17.0868 24.8312 15.2375 24.8312 12.9564C24.8312 10.6752 22.9819 8.82593 20.7007 8.82593C18.4196 8.82593 16.5703 10.6752 16.5703 12.9564C16.5703 15.2375 18.4196 17.0868 20.7007 17.0868Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M22.7461 16.5221L26.8042 24.6529'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M18.6343 16.5706L14.5938 24.6518'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </g>
    </IconWrapper>
  );
});
