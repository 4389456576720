import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagTH = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-th'} {...props} >
      <circle cx={'12.02'} cy={'12'} r={'11.98'} fill={'#f0f0f0'} />
      <path d={'M23.22,7.83H.75a12,12,0,0,0,0,8.34H23.22A12,12,0,0,0,23.22,7.83Z'} fill={'#0052b4'} />
      <path d={'M12,0A11.92,11.92,0,0,0,2.9,4.19H21.07A12,12,0,0,0,12,0Z'} fill={'#d80027'} />
      <path d={'M21.07,19.81H2.9a12,12,0,0,0,16.9,1.27A11.71,11.71,0,0,0,21.07,19.81Z'} fill={'#d80027'} />
    </IconWrapper>
  );
});
